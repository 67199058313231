<template>
  <div class="interchange-1 noselect">
    <div class="title h3">SRI Open-core</div>
    <picture class="comp-image-1" v-if="tableProp">
      <source srcset="~@/assets/comp_open.png" type="image/png">
      <img src="~@/assets/comp_open.png" alt="Comparison SRI Open-core" />
    </picture>
    <picture class="comp-image-1" v-else-if="!tableProp">
      <source srcset="~@/assets/comp_open-hs.png" type="image/png">
      <img src="~@/assets/comp_open-hs.png" alt="Comparison SRI Open-core" />
    </picture>
    <div class="area">
      <h4 class="h4">Radius</h4>
      <div>Meters</div>
    </div>
    <div class="area-value" v-if="tableProp">381</div>
    <div class="area-value" v-else-if="!tableProp">763</div>
    <div class="traffic">
      <h4 class="h4"><span class="bold">Area:</span><span class="small"> build + trapped</span></h4>
      <div>Hectares</div>
    </div>
    <div class="traffic-value" v-if="tableProp">12.0</div>
    <div class="traffic-value" v-else-if="!tableProp">35.6</div>
    <div class="construction" v-if="tableProp">
      <h4 class="h4">Traffic capacity</h4>
      <div>Vehicles p. lane / hour</div>
    </div>
    <div class="construction" v-else-if="!tableProp">
      <h4 class="h4">Crossing length</h4>
      <div>Meters</div>
    </div>
    <div class="construction-value" v-if="tableProp">1,800</div>
    <div class="construction-value" v-else-if="!tableProp">1,527</div>
    <div class="efficiency">
      <h4 class="h4">Efficiency</h4>
      <div>Vehicles per hectare</div>
    </div>
    <div class="efficiency-value" v-if="tableProp">150</div>
    <div class="efficiency-value" v-else-if="!tableProp">56</div>
    <div class="efficiency-2">
      <h4 class="h4">Construction cost</h4>
      <div>Million dollars (approx.)</div>
    </div>
    <div class="efficiency-2-value" v-if="tableProp">58</div>
    <div class="efficiency-2-value" v-else-if="!tableProp">151</div>
  </div>
</template>

<script>
export default {
  name: 'Interchange1',
  props: ['tableProp']
}
</script>

<style lang="scss" scoped>
.interchange-1 {
  @include grid;
  margin-top: 0;
  .title {
    grid-column: 2 / 10;
    grid-row: 1 / 2;
    margin-top: 32px;
    margin-bottom: 32px;
    text-align: center;
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .comp-image-1 {
    grid-column: 2 / 10;
    grid-row: 2 / 3;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    @include phone {
      grid-column: 2 / 6;
    }
    img {
      width: 80%;
      max-width: 600px;
    }
  }
  .area {
    grid-column: 2 / 5;
    grid-row: 3 / 4;
  }
  .area-value {
    grid-column: 5 / 6;
    grid-row: 3 / 4;
    margin-top: auto;
  }
  .traffic {
    grid-column: 2 / 5;
    grid-row: 4 / 5;
    margin-top: 32px;
  }
  .traffic-value {
    grid-column: 5 / 6;
    grid-row: 4 / 5;
    margin-top: auto;
  }
  .construction {
    grid-column: 2 / 5;
    grid-row: 5 / 6;
    margin-top: 32px;
  }
  .construction-value {
    grid-column: 5 / 6;
    grid-row: 5 / 6;
    margin-top: auto;
  }
  .efficiency {
    grid-column: 2 / 5;
    grid-row: 6 / 7;
    margin-top: 32px;
  }
  .efficiency-value {
    grid-column: 5 / 6;
    grid-row: 6 / 7;
    margin-top: auto;
  }
  .efficiency-2 {
    grid-column: 2 / 5;
    grid-row: 7 / 8;
    margin-top: 32px;
  }
  .efficiency-2-value {
    grid-column: 5 / 6;
    grid-row: 7 / 8;
    margin-top: auto;
  }
}
</style>
