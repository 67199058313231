<template>
  <div class="comparison up-1" v-scrollanimate>
    <div class="comparison-intro">
      <hr class="line">
      <div class="table-title">
        <div class="title-box t-1">
          <h2 class="h3 title-block">Comparisons</h2>
        </div>
      </div>
    </div>
    <div class="comparison-desktop">
      <div class="interchanges-row-0-b">
        <div class="v-text bold">CPRS in a Minimal-Area SRI</div>
        <div class="v-text bold">CPRS in a High-Speed SRI</div>
      </div>
      <div class="interchanges-row-1-b">
        <div class="i-column">
          <picture class="comp-image">
            <source srcset="~@/assets/cprs-ls.png" type="image/png">
            <img src="~@/assets/cprs-ls.png" alt="Comparison low speed" />
          </picture>
        </div>
        <div class="i-column">
          <picture class="comp-image">
            <source srcset="~@/assets/cprs-hs.png" type="image/png">
            <img src="~@/assets/cprs-hs.png" alt="Comparison high speed" />
          </picture>
        </div>
      </div>
      <div class="interchanges-row-x-a i-row-3">
        <div class="legend-s"><span class="bold">Land Footprint</span><br><span class="small">Hectares</span></div>
      </div>
      <div class="interchanges-row-x-b i-row-3">
        <div class="v-text">2.07</div>
        <div class="v-text">9.26</div>
      </div>
      <div class="interchanges-row-x-a i-row-4">
        <div class="legend-s"><span class="bold">Total parking area</span> (4 levels)<br><span class="small">Hectares</span></div>
      </div>
      <div class="interchanges-row-x-b i-row-4">
        <div class="v-text">4.78</div>
        <div class="v-text">26.95</div>
      </div>
      <div class="interchanges-row-x-a i-row-5">
        <div class="legend-s"><span class="bold">Total parking places</span> (4 levels)<br><span class="small">Cars</span></div>
      </div>
      <div class="interchanges-row-x-b i-row-5">
        <div class="v-text">1,900</div>
        <div class="v-text">10,420</div>
      </div>
      <div class="interchanges-row-x-a i-row-6">
        <div class="legend-s"><span class="bold">Buses area</span> (Level 5)<br><span class="small">Hectares</span></div>
      </div>
      <div class="interchanges-row-x-b i-row-6">
        <div class="v-text">0.91</div>
        <div class="v-text">5.6</div>
      </div>
      <div class="interchanges-row-x-a i-row-7">
        <div class="legend-s"><span class="bold">Bus parking places</span> (Level 5)<br><span class="small">Buses</span></div>
      </div>
      <div class="interchanges-row-x-b i-row-7">
        <div class="v-text">90</div>
        <div class="v-text">560</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SolutionComparison'
}
</script>

<style lang="scss" scoped>
.legend-s {
  line-height: 22px;
}
.comparison-intro {
  @include grid;
  margin-top: 128px;
  @include laptop {
    margin-top: 96px;
  }
  @include tablet {
    margin-top: 48px;
  }
  .line {
    grid-column: 2 / 14;
    @include tablet {
      display: none;
    }
  }
  .table-title {
    grid-column: 2 / 14;
    margin-top: 32px;
    margin-bottom: 56px;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    .title-box {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      @include phone {
        flex-direction: column;
      }
    }
  }
}
.comparison-desktop {
  @include grid;
  margin-bottom: 128px;
  row-gap: 2rem;
  @include laptop {
    margin-bottom: 96px;
  }
  .i-column {
    position: relative;
  }
  .t-text {
    position: absolute;
    top: 8px;
    left: 18px;
    transform-origin: top left;
    transform: rotate(45deg);
  }
  .b-text {
    position: absolute;
    bottom: 8px;
    left: 8px;
  }
  .interchanges-row-0-b {
    grid-column: 5 / 14;
    grid-row: 1;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    .v-text {
      width: 100%;
    }
  }
  .interchanges-row-1-b {
    grid-column: 5 / 14;
    grid-row: 2;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    img {
      width: 100%;
      vertical-align: bottom;
    }
  }
  .interchanges-row-x-a {
    grid-column: 2 / 5;
    @include tablet {
      grid-column: 2 / 5;
    }
    @include phone {
      grid-column: 2 / 4;
    }
  }
  .interchanges-row-x-b {
    grid-column: 5 / 14;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    @include tablet {
      grid-column: 5 / 10;
    }
    @include phone {
      grid-column: 4 / 6;
    }
    .v-text {
      width: 100%;
      // margin-top: auto;
    }
  }
  .i-row-3 {
    grid-row: 3;
  }
  .i-row-4 {
    grid-row: 4;
  }
  .i-row-5 {
    grid-row: 5;
  }
  .i-row-6 {
    grid-row: 6;
  }
  .i-row-7 {
    grid-row: 7;
  }
}
</style>
