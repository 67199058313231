<template>
  <div class="content-2 up-1" v-scrollanimate>
    <div id="sri" class="video-container">
      <iframe class="iframe" src="https://player.vimeo.com/video/664700260?h=54d0fadad8&autoplay=0&loop=1&background=0" width="100%" frameborder="0" allow="fullscreen"></iframe>
    </div>
    <div class="t-1"><h4 class="h3 ttl">SRI — Spiral Ring Interchange</h4><div class="h6 subt">The most efficient complete system interchange</div></div>
    <div class="p-1"><span class="bold">The Spiral Ring Interchange (SRI) is a patented, innovative design geometry with a spiral construction that provides minimal land footprint and maximal vehicle flow. Its unique layout of tightly knit left-turns forgoes the need for widely-spread ramps or multi-level overpasses, resulting in the smallest complete system interchange in existence.</span><br><br>This solution involves dedicated lanes to all directions with no lane swapping or weaving issues. It is a highly-scalable system that offers optional additional parallel lanes with no loss of safety or efficiency, and even a built-in possibility to correct navigation mistakes (U-turns).<br><br>Additionally, its unique geometry enables its trapped spaces to be used for a Park & Ride system, as well as for a public transportation hub and an urban services hub. The Spiral Ring Interchange comes in several variations that address different urban needs.</div>
    <!-- <div class="tech bold"><span class="noselect t-click eff-base-r eff-2 eff-b" @click="showExpand = !showExpand">Technical details <span v-html="symbol()"></span></span></div> -->
    <DetailsSRI class="details" />
    <DetailsSRI2 class="details-2" />
    <!-- <ExpandSRI class="expanded" :class="{active: showExpand}" /> -->
  </div>
</template>

<script>
import DetailsSRI from '../components/DetailsSRI'
import DetailsSRI2 from '../components/DetailsSRI2'
// import ExpandSRI from '../components/ExpandSRI'

export default {
  name: 'SolutionContent2',
  components: {
    DetailsSRI,
    DetailsSRI2
    // ExpandSRI
  }
  // data () {
  //   return {
  //     showExpand: false,
  //     symbolPlus: '+',
  //     symbolMinus: '–'
  //   }
  // },
  // methods: {
  //   symbol () {
  //     const closed = this.symbolPlus
  //     const open = this.symbolMinus
  //     if (this.showExpand) return open
  //     return closed
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.content-2 {
  @include grid;
  margin-bottom: 128px;
  @include laptop {
    margin-bottom: 96px;
  }
  @include tablet {
    margin-bottom: 64px;
  }
  .video-container {
    grid-column: 1 / 15;
    grid-row: 1 / 2;
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    &::before {
      display: block;
      content: "";
      padding-top: 41%;
    }
    .iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  .t-1 {
    grid-column: 2 / 6;
    grid-row: 2 / 3;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      margin-top: 64px;
    }
    .ttl {
      margin-bottom: 20px;
    }
    .subt {
      max-width: 426px;
      @include phone {
        max-width: 100%;
      }
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 3 / 4;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .details {
    grid-column: 1 / 15;
    grid-row: 3 / 4;
    margin-top: 126px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 1 / 11;
      grid-row: 4 / 5;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 1 / 7;
    }
  }
  .details-2 {
    grid-column: 1 / 15;
    grid-row: 4 / 5;
    @include tablet {
      grid-column: 1 / 11;
      grid-row: 5 / 6;
    }
    @include phone {
      grid-column: 1 / 7;
    }
  }
  .tech {
    grid-column: 8 / 13;
    grid-row: 5 / 6;
    @include tablet {
      grid-column: 2 / 6;
      grid-row: 6 / 7;
    }
    .t-click {
      cursor: pointer;
    }
  }
  .expanded {
    grid-column: 1 / 15;
    grid-row: 6 / 7;
    transition: 0.4s ease;
    max-height: 0;
    overflow: hidden;
    @include tablet {
      grid-column: 1 / 11;
      grid-row: 7 / 8;
    }
    @include phone {
      grid-column: 1 / 7;
    }
  }
  .active {
    max-height: 500px;
    transition: all 1s ease;
  }
}

</style>
