<template>
  <div class="solution page">
    <SolutionHero/>
    <SolutionContent1/>
    <SolutionContent2/>
    <SolutionComparisonAlt/>
    <SolutionContent3/>
    <SolutionComparison2/>
    <SolutionContent4/>
    <SolutionCallToAction/>
    <NavFooter/>
  </div>
</template>

<script>
import SolutionHero from '../components/SolutionHero'
import SolutionContent1 from '../components/SolutionContent1'
import SolutionContent2 from '../components/SolutionContent2'
import SolutionComparisonAlt from '../components/SolutionComparisonAlt'
import SolutionContent3 from '../components/SolutionContent3'
import SolutionComparison2 from '../components/SolutionComparison2'
import SolutionContent4 from '../components/SolutionContent4'
import SolutionCallToAction from '../components/SolutionCallToAction'
import NavFooter from '../components/NavFooter'

export default {
  name: 'Solution',
  components: {
    SolutionHero,
    SolutionContent1,
    SolutionContent2,
    SolutionComparisonAlt,
    SolutionContent3,
    SolutionComparison2,
    SolutionContent4,
    SolutionCallToAction,
    NavFooter
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>

</style>
