<template>
  <div class="details-sri-2 up-1" v-scrollanimate>
    <picture class="b-image-1">
        <!-- <source srcset="~@/assets/details-1.webp" type="image/webp"> -->
        <source srcset="~@/assets/sri-6.png" type="image/png">
        <img src="~@/assets/sri-6.png" alt="SRI 6-way" />
    </picture>
    <h5 class="h4 t-1">SRI-3 and SRI-6</h5>
    <div class="p-1">In addition to the standard 4-direction complete system interchange, SRI has a 3-direction variant as well as a one-point, full-access, 6-direction variant. While currently available solutions for 6-directions require building two separate interchanges, SRI offers a layout that is easier to drive through, with dedicated lanes to all directions, yet still uses less than half the amount of land.</div>
  </div>
</template>

<script>
export default {
  name: 'DetailsSRI2'
}
</script>

<style lang="scss" scoped>
.details-sri-2 {
  @include grid;
  .b-image-1 {
    grid-column: 2 / 7;
    grid-row: 1 / 2;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-1 {
    grid-column: 2 / 7;
    grid-row: 2 / 3;
    margin-top: 40px;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 2 / 7;
    grid-row: 3 / 4;
    margin-top: 12px;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
}

</style>
