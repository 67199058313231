<template>
  <div class="content-1 up-1" v-scrollanimate>
    <h2 class="h4 t-1">Our novel range of solutions includes a complete system highway interchange, a public transportation hub with Park & Ride, and an urban services hub — all in one complex, with zero added land.</h2>
    <div class="p-1">By using SRI and CPRS within a single complex we can lower traffic congestion, reduce emissions, and minimize time spent on commuting.<br><br>Public costs for construction are lowered by building on a much smaller amount of land, as well as giving franchises incentives for constructing the complex in return for the right to operate their services.<br><br>A satellite system of such complexes built around an urban area can reduce the amount of cars entering the city by the tens of thousands, creating a huge positive impact.</div>
  </div>
</template>

<script>
export default {
  name: 'SolutionContent1'
}
</script>

<style lang="scss" scoped>
.content-1 {
  @include grid;
  margin-top: 128px;
  margin-bottom: 128px;
  @include laptop {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  @include tablet {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .t-1 {
    grid-column: 8 / 13;
    grid-row: 1 / 2;
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-top: 54px;
    @include laptop {
      margin-top: 32px;
    }
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      margin-top: 26px;
      grid-column: 2 / 6;
    }
  }
}

</style>
