<template>
  <div class="comparison up-1" v-scrollanimate>
    <div class="comparison-intro">
      <hr class="line">
      <div class="table-title">
        <div class="title-box t-1">
          <h2 class="h3 title-block">Comparisons</h2>
          <div class="btn-box">
            <button class="btn btn-switch" :class="{ 'btn-active': tableOne }" @click="switchOne">Minimal Area</button>
            <button class="btn btn-switch btn-2" :class="{ 'btn-active': !tableOne }"  @click="switchTwo">High Speed</button>
          </div>
        </div>
        <transition name="fade" mode="out-in">
          <div class="sub-m" key="1" v-if="tableOne">
            <div>Minimal land area</div>
            <div class="sub-s mini">
              <div>0</div>
              <img class="img-low" src="~@/assets/scale.svg" alt="Scale" />
              <div>500m</div>
            </div>
          </div>
          <div class="sub-m" key="2" v-else-if="!tableOne">
            <div>High turn speed (90 km/h)</div>
            <div class="sub-s mini">
              <div>0</div>
              <img class="img-high" src="~@/assets/scale-2.svg" alt="Scale" />
              <div>500m</div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="comparison-desktop">
      <div class="interchanges-row-0-b">
        <div class="v-text bold blue">SRI Compact</div>
        <div class="v-text bold blue">SRI Open-core</div>
        <div class="v-text bold">Roundabout</div>
        <div class="v-text bold">Turbine</div>
        <div class="v-text bold">Stack</div>
        <div class="v-text bold">Cloverleaf</div>
      </div>
      <div class="interchanges-row-1-a">
        <div class="legend-s"><span class="bold">Radius</span><br><span class="small">Meters</span></div>
        <div class="legend-s"><span class="bold">Area:</span> <span class="mini">build + trapped</span><br><span class="small">Hectares</span></div>
      </div>
      <transition name="fade">
        <div key="1" class="interchanges-row-1-b" v-if="tableOne">
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_sri.png" type="image/png">
              <img src="~@/assets/comp_sri.png" alt="Comparison SRI" />
            </picture>
            <div class="t-text blue meta">R321</div>
            <div class="b-text blue meta">8.0</div>
          </div>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_open.png" type="image/png">
              <img src="~@/assets/comp_open.png" alt="Comparison SRI Open-core" />
            </picture>
            <div class="t-text blue meta">R381</div>
            <div class="b-text blue meta">12.0</div>
          </div>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_round.png" type="image/png">
              <img src="~@/assets/comp_round.png" alt="Comparison roundabout" />
            </picture>
            <div class="t-text meta">R512</div>
            <div class="b-text meta">15.6</div>
          </div>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_turbine.png" type="image/png">
              <img src="~@/assets/comp_turbine.png" alt="Comparison turbine" />
            </picture>
            <div class="t-text meta">R487</div>
            <div class="b-text meta">13.0</div>
          </div>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_stack.png" type="image/png">
              <img src="~@/assets/comp_stack.png" alt="Comparison stack" />
            </picture>
            <div class="t-text meta">R415</div>
            <div class="b-text meta">10.6</div>
          </div>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_clover.png" type="image/png">
              <img src="~@/assets/comp_clover.png" alt="Comparison cloverfield" />
            </picture>
            <div class="t-text meta">R388</div>
            <div class="b-text meta">15.1</div>
          </div>
        </div>
        <div key="2" class="interchanges-row-1-b" v-else>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_sri-hs.png" type="image/png">
              <img src="~@/assets/comp_sri-hs.png" alt="Comparison SRI" />
            </picture>
            <div class="t-text meta blue">R614</div>
            <div class="b-text meta blue">26.4</div>
          </div>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_open-hs.png" type="image/png">
              <img src="~@/assets/comp_open-hs.png" alt="Comparison SRI Open-core" />
            </picture>
            <div class="t-text meta blue">R763</div>
            <div class="b-text meta blue">35.6</div>
          </div>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_round-hs.png" type="image/png">
              <img src="~@/assets/comp_round-hs.png" alt="Comparison roundabout" />
            </picture>
            <div class="t-text meta">R846</div>
            <div class="b-text meta">68.9</div>
          </div>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_turbine-hs.png" type="image/png">
              <img src="~@/assets/comp_turbine-hs.png" alt="Comparison turbine" />
            </picture>
            <div class="t-text meta">R973</div>
            <div class="b-text meta">51.1</div>
          </div>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_stack-hs.png" type="image/png">
              <img src="~@/assets/comp_stack-hs.png" alt="Comparison stack" />
            </picture>
            <div class="t-text meta">R725</div>
            <div class="b-text meta">25.1</div>
          </div>
          <div class="i-column">
            <picture class="comp-image">
              <source srcset="~@/assets/comp_clover-hs.png" type="image/png">
              <img src="~@/assets/comp_clover-hs.png" alt="Comparison cloverfield" />
            </picture>
            <div class="t-text meta">R1026</div>
            <div class="b-text meta">125.5</div>
          </div>
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div key="1" class="interchanges-row-2-a" v-if="tableOne">
          <div class="legend-s"><span class="bold">Traffic capacity</span><br><span class="small">Vehicles p. lane / hour</span></div>
        </div>
        <div key="2" class="interchanges-row-2-a" v-else>
          <div class="legend-s"><span class="bold">Crossing length</span><br><span class="small">Meters</span></div>
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div key="1" class="interchanges-row-2-b" v-if="tableOne">
          <div class="v-text blue">1,800</div>
          <div class="v-text blue">1,800</div>
          <div class="v-text">1,750</div>
          <div class="v-text">1,800</div>
          <div class="v-text">1,850</div>
          <div class="v-text">1,400</div>
        </div>
        <div key="2" class="interchanges-row-2-b" v-else>
          <div class="v-text blue">1,228</div>
          <div class="v-text blue">1,527</div>
          <div class="v-text">1,758</div>
          <div class="v-text">1,692</div>
          <div class="v-text">1,450</div>
          <div class="v-text">2,028</div>
        </div>
      </transition>
      <div class="interchanges-row-3-a">
        <div class="legend-s"><span class="bold">Efficiency</span><br><span class="small">Vehicles per hectare</span></div>
      </div>
      <transition name="fade" mode="out-in">
        <div key="1" class="interchanges-row-3-b" v-if="tableOne">
          <div class="v-text blue">225</div>
          <div class="v-text blue">150</div>
          <div class="v-text">113</div>
          <div class="v-text">140</div>
          <div class="v-text">174</div>
          <div class="v-text">93</div>
        </div>
        <div key="2" class="interchanges-row-3-b" v-else>
          <div class="v-text blue">76</div>
          <div class="v-text blue">56</div>
          <div class="v-text">34</div>
          <div class="v-text">39</div>
          <div class="v-text">80</div>
          <div class="v-text">16</div>
        </div>
      </transition>
      <div class="interchanges-row-4-a">
        <div class="legend-s"><span class="bold">Construction cost</span><br><span class="small">Million dollars (approx.)</span></div>
      </div>
      <transition name="fade" mode="out-in">
        <div key="1" class="interchanges-row-4-b" v-if="tableOne">
          <div class="v-text blue">75</div>
          <div class="v-text blue">58</div>
          <div class="v-text">73</div>
          <div class="v-text">76</div>
          <div class="v-text">193</div>
          <div class="v-text">39</div>
        </div>
        <div key="2" class="interchanges-row-4-b" v-else>
          <div class="v-text blue">130</div>
          <div class="v-text blue">151</div>
          <div class="v-text">174</div>
          <div class="v-text">197</div>
          <div class="v-text">365</div>
          <div class="v-text">107</div>
        </div>
      </transition>
      <div class="disclamer small">
        <div class="note">
          <div class="square"></div>
          <div class="small">Land use</div>
        </div>
        <br>
        <div>Regional parameters and standards may vary in different locations</div>
      </div>
    </div>
    <agile class="agile comparison-mobile">
      <div class="slide-1">
        <Interchange1 :tableProp="tableOne" />
      </div>
      <div class="slide-2">
        <Interchange2 :tableProp="tableOne" />
      </div>
      <div class="slide-3">
        <Interchange3 :tableProp="tableOne" />
      </div>
      <div class="slide-4">
        <Interchange4 :tableProp="tableOne" />
      </div>
      <div class="slide-5">
        <Interchange5 :tableProp="tableOne" />
      </div>
      <div class="slide-6">
        <Interchange6 :tableProp="tableOne" />
      </div>
      <template slot="prevButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.243" height="36.243" viewBox="0 0 20.243 36.243" id="left-b"><path d="M29.5,41l-16-16,16-16" transform="translate(-11.379 -6.879)" fill="none" stroke="#0090d1" stroke-linecap="square" stroke-width="3"/></svg>
      </template>
      <template slot="nextButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.243" height="36.243" viewBox="0 0 20.243 36.243" id="right-b"><path d="M29.5,41l-16-16,16-16" transform="translate(-11.379 -6.879)" fill="none" stroke="#0090d1" stroke-linecap="square" stroke-width="3"/></svg>
      </template>
    </agile>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
import Interchange1 from '../components/Interchange1'
import Interchange2 from '../components/Interchange2'
import Interchange3 from '../components/Interchange3'
import Interchange4 from '../components/Interchange4'
import Interchange5 from '../components/Interchange5'
import Interchange6 from '../components/Interchange6'
// import Interchange1b from '../components/Interchange1b'
// import Interchange2b from '../components/Interchange2b'
// import Interchange3b from '../components/Interchange3b'
// import Interchange4b from '../components/Interchange4b'
// import Interchange5b from '../components/Interchange5b'

export default {
  name: 'SolutionComparison',
  components: {
    agile: VueAgile,
    Interchange1,
    Interchange2,
    Interchange3,
    Interchange4,
    Interchange5,
    Interchange6
  },
  data () {
    return {
      tableOne: true
    }
  },
  methods: {
    switchOne () {
      this.tableOne = true
    },
    switchTwo () {
      this.tableOne = false
    }
  }
}
</script>

<style lang="scss" scoped>
.legend-s {
  line-height: 22px;
}
.comparison-intro {
  @include grid;
  margin-top: 128px;
  @include laptop {
    margin-top: 96px;
  }
  @include tablet {
    margin-top: 48px;
  }
  .line {
    grid-column: 2 / 14;
    @include tablet {
      display: none;
    }
  }
  .table-title {
    grid-column: 2 / 14;
    margin-top: 32px;
    margin-bottom: 56px;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    .title-box {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .btn-box {
        display: flex;
        margin-top: 24px;
        @include tablet {
          margin-top: 12px;
        }
        .btn-switch {
          width: 276px;
          height: 52px;
          color: $c-g;
          background-color: white;
          border: 1px solid $c-g;
          border-radius: 26px;
          &:hover {
            color: white;
            background-color: $c-e;
            border: 1px solid $c-e;
          }
          @include laptop {
            width: 212px;
            height: 40px;
            border-radius: 20px;
          }
          @include tablet {
            width: 168px;
            height: 36px;
            border-radius: 18px;
          }
          @include phone {
            width: 100%;
          }
        }
        .btn-2 {
          margin-left: 24px;
          @include tablet {
            margin-left: 12px;
          }
        }
        .btn-active {
          color: white !important;
          background-color: $c-e !important;
          border: 1px solid $c-e !important;
        }
      }
    }
    .sub-m {
      display: flex;
      justify-content: space-between;
      .sub-s {
        display: flex;
        @include tablet {
          display: none;
        }
        .img-low {
          width: 5.8vw;
          width: calc((((100vw - 408px)/12) * 10 + (9 * 24px) - (5 * 16px)) / 12);
        }
        .img-high {
          width: 5.8vw;
          width: calc((((100vw - 408px)/12) * 10 + (9 * 24px) - (5 * 16px)) / 24);
        }
      }
    }
  }
}
.comparison-desktop {
  @include grid;
  margin-bottom: 128px;
  row-gap: 2rem;
  @include laptop {
    margin-bottom: 96px;
  }
  @include tablet {
    display: none;
  }
  .i-column {
    position: relative;
  }
  .blue {
    color: $c-e !important;
  }
  .t-text {
    position: absolute;
    top: 5px;
    left: 18px;
    transform-origin: top left;
    transform: rotate(45deg);
  }
  .b-text {
    position: absolute;
    bottom: 8px;
    left: 8px;
  }
  .interchanges-row-0-b {
    grid-column: 4 / 14;
    grid-row: 1;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    .v-text {
      width: 100%;
    }
  }
  .interchanges-row-1-a {
    grid-column: 2 / 4;
    grid-row: 2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .interchanges-row-1-b {
    grid-column: 4 / 14;
    grid-row: 2;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    img {
      width: 100%;
      vertical-align: bottom;
    }
  }
  .interchanges-row-2-a {
    grid-column: 2 / 4;
    grid-row: 3;
  }
  .interchanges-row-2-b {
    grid-column: 4 / 14;
    grid-row: 3;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    .v-text {
      width: 100%;
      margin-top: auto;
    }
  }
  .interchanges-row-3-a {
    grid-column: 2 / 4;
    grid-row: 4;
  }
  .interchanges-row-3-b {
    grid-column: 4 / 14;
    grid-row: 4;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    .v-text {
      width: 100%;
      margin-top: auto;
    }
  }
  .interchanges-row-4-a {
    grid-column: 2 / 4;
    grid-row: 5;
  }
  .interchanges-row-4-b {
    grid-column: 4 / 14;
    grid-row: 5;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    .v-text {
      width: 100%;
      margin-top: auto;
    }
  }
  .legend {
    grid-column: 2 / 5;
    grid-row: 6;
    margin-top: auto;
  }
  .disclamer {
    grid-column: 2 / 5;
    grid-row: 7;
  }
  .note {
    display: flex;
    .square {
      height: 16px;
      width: 16px;
      background-color: $c-h;
      margin-right: 12px;
      align-self: flex-start;
    }
  }
}
.comparison-mobile {
  display: none;
  @include tablet {
    display: block;
    margin-bottom: 64px;
  }
}

#right-b {
  transform: rotate(180deg);
}

::v-deep .agile__actions {
  position: fixed;
  width: 100%;
  top: 200px;
  @include phone {
    top: 240px;
  }
}

::v-deep .agile__nav-button {
  background: none !important;
  margin: 0 48px;
  @include phone {
    margin: 0 24px;
  }
}

// .opacity-fade-enter-active {
//   transition: all .3s ease;
// }
// .opacity-fade-leave-active {
//   transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
// }
// .opacity-fade, .opacity-fade-leave-to {
//   opacity: 0;
// }
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s ease-in;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
