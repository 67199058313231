<template>
  <div class="content-3 up-1" v-scrollanimate>
    <div id="cprs" class="video-container">
      <iframe class="iframe" src="https://player.vimeo.com/video/664700171?h=d48e117ede&autoplay=0&loop=1&background=0" width="100%" frameborder="0" allow="fullscreen"></iframe>
    </div>
    <div class="t-1"><h4 class="h3 ttl">CPRS — Complete Park & Ride System</h4><div class="h6 subt">The ideal urban exchange point between private and public transportation</div></div>
    <div class="p-1"><span class="bold">The Complete Park & Ride System (CPRS) is a patented, innovative, efficient and user-friendly facility that combines parking areas, public transportation, an urban services hub, and safe pedestrian accessibility.</span><br><br>It can integrate with roads, intersections and interchanges to provide an efficient exchange point between different modes of transportation. Most optimally combined with SRI, it transforms the trapped land within the interchange into a functioning, valuable asset.<br><br>CPRS is directly accessible from all four directions of the highway, and it provides separate access paths for cars and for buses. Cars have direct access to multi-level parking platforms and to a pick-up/drop-off area. Buses have direct access to a public transportation hub on the top of the structure. All of them have direct access back to the highway in any driving direction.</div>
    <DetailsCPRS class="details" />
  </div>
</template>

<script>
import DetailsCPRS from '../components/DetailsCPRS'

export default {
  name: 'SolutionContent3',
  components: {
    DetailsCPRS
  }
}
</script>

<style lang="scss" scoped>
.content-3 {
  @include grid;
  margin-bottom: 128px;
  @include laptop {
    margin-bottom: 96px;
  }
  @include tablet {
    margin-bottom: 64px;
  }
  .video-container {
    grid-column: 1 / 15;
    grid-row: 1 / 2;
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    // padding: 56.25% 0 0 0;
    // position: relative;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    &::before {
      display: block;
      content: "";
      padding-top: 41%;
    }
    .iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      // position: absolute;
      // top: 0;
      // left: 0;
      // width: 100%;
      // height: 100%;
    }
  }
  .t-1 {
    grid-column: 2 / 6;
    grid-row: 2 / 3;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      margin-top: 64px;
    }
    .ttl {
      margin-bottom: 20px;
    }
    .subt {
      max-width: 426px;
      @include phone {
        max-width: 100%;
      }
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 3 / 4;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .details {
    grid-column: 1 / 15;
    grid-row: 3 / 4;
    margin-top: 126px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 1 / 11;
      grid-row: 4 / 5;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 1 / 7;
    }
  }
  .details-2 {
    grid-column: 1 / 15;
    grid-row: 4 / 5;
    @include tablet {
      grid-column: 1 / 11;
      grid-row: 5 / 6;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 1 / 7;
    }
  }
}
</style>
