<template>
  <div class="hero">
    <h1 class="h2 t-1">We bring the <span class="italics">smart</span> of ‘smart cities’ to the design of transportation infrastructure</h1>
    <div id="solution-hero" class="video-container">
      <iframe class="iframe" src="https://player.vimeo.com/video/632970136?h=df342cdc13&autoplay=1&loop=1&background=1" width="100%" frameborder="0" allow="fullscreen"></iframe>
    </div>
    <!-- <picture class="hero-image">
        <source srcset="~@/assets/s_hero.webp" type="image/webp">
        <source srcset="~@/assets/s_hero.jpg" type="image/jpeg">
        <img src="~@/assets/s_hero.jpg" alt="Solution" />
    </picture> -->
  </div>
</template>

<script>
export default {
  name: 'SolutionHero'
}
</script>

<style lang="scss" scoped>
.hero {
  @include grid;
  margin-top: 272px;
  @include laptop {
    margin-top: 192px;
  }
  @include phone {
    margin-top: 132px;
  }
  .t-1 {
    grid-column: 2 / 8;
    grid-row: 1 / 2;
    margin-bottom: 52px;
    @include laptop {
      margin-bottom: 36px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .video-container {
    grid-column: 1 / 15;
    grid-row: 2 / 3;
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    &::before {
      display: block;
      content: "";
      padding-top: 40%;
    }
    .iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
    // img {
    //   width: 100%;
    //   vertical-align: bottom;
    //   @include phone {
    //     height: 66.7vw;
    //     object-fit: cover;
    //   }
    // }
  }
}

</style>
