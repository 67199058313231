<template>
  <div class="details-sri up-1" v-scrollanimate>
    <picture class="b-image-1">
        <!-- <source srcset="~@/assets/details-1.webp" type="image/webp"> -->
        <source srcset="~@/assets/sri_compact.png" type="image/png">
        <img src="~@/assets/sri_compact.png" alt="SRI Compact" />
    </picture>
    <h5 class="h4 t-1">SRI Compact</h5>
    <div class="p-1">SRI Compact enables a complete system interchange to be built in tight urban spaces previously considered fit only for road junctions. Alternatively, when built on the edge of a city or on out-of-city highways, SRI Compact allows high-speed interchanges to require a smaller land-footprint, substantially reducing environmental impact. This is achieved by placing the right turns <span class="italics">inside</span> the central area of the interchange (or left turns for left-side driving countries).</div>
    <picture class="b-image-2">
        <!-- <source srcset="~@/assets/details-2.webp" type="image/webp"> -->
        <source srcset="~@/assets/sri_open.png" type="image/png">
        <img src="~@/assets/sri_open.png" alt="SRI Open-core" />
    </picture>
    <h5 class="h4 t-2">SRI Open-core (CPRS ready)</h5>
    <div class="p-2">SRI Open-core transforms the trapped spaces within highway interchanges into accessible land for strategic uses. In combination with CPRS, it can contain a transportation and urban services hub without requiring any additional land. This is achieved by placing the right turns <span class="italics">outside</span> the central area of the interchange (or left turns for left-side driving countries).</div>
  </div>
</template>

<script>
export default {
  name: 'DetailsSRI'
}
</script>

<style lang="scss" scoped>
.details-sri {
  @include grid;
  margin-top: 32px;
  .b-image-1 {
    grid-column: 2 / 7;
    grid-row: 1 / 2;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-1 {
    grid-column: 2 / 7;
    grid-row: 2 / 3;
    margin-top: 40px;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 2 / 7;
    grid-row: 3 / 4;
    margin-top: 12px;
    margin-bottom: 96px;
    @include laptop {
      margin-bottom: 64px;
    }
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .b-image-2 {
    grid-column: 8 / 13;
    grid-row: 1 / 2;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 4 / 5;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-2 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-top: 40px;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 5 / 6;
    }
  }
  .p-2 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    margin-top: 12px;
    margin-bottom: 96px;
    @include laptop {
      margin-bottom: 64px;
    }
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 6 / 7;
    }
  }
  .b-image-3 {
    grid-column: 2 / 7;
    grid-row: 4 / 5;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 7 / 8;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-3 {
    grid-column: 2 / 7;
    grid-row: 5 / 6;
    margin-top: 32px;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 8 / 9;
    }
  }
  .p-3 {
    grid-column: 2 / 7;
    grid-row: 6 / 7;
    margin-top: 32px;
    margin-bottom: 96px;
    @include laptop {
      margin-bottom: 64px;
    }
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 9 / 10;
    }
  }
  .b-image-4 {
    grid-column: 8 / 13;
    grid-row: 4 / 5;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 10 / 11;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-4 {
    grid-column: 8 / 13;
    grid-row: 5 / 6;
    margin-top: 32px;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 11 / 12;
    }
  }
  .p-4 {
    grid-column: 8 / 13;
    grid-row: 6 / 7;
    margin-top: 32px;
    margin-bottom: 96px;
    @include laptop {
      margin-bottom: 64px;
    }
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 12 / 13;
    }
  }
}

</style>
