<template>
  <div class="details-sri up-1" v-scrollanimate>
    <picture class="b-image-1">
        <!-- <source srcset="~@/assets/details-b-1.webp" type="image/webp"> -->
        <source srcset="~@/assets/sri_park.png" type="image/png">
        <img src="~@/assets/sri_park.png" alt="SRI parking" />
    </picture>
    <h5 class="h4 t-1">Park & Ride</h5>
    <div class="p-1">A private car may be the best way to leave home, but the worst way to enter a city. CPRS allows commuters to leave their cars right by the highway, at the city’s outskirts, and seamlessly transition to more efficient modes of transport. This solution can provide many thousands of parking places optimally distributed for easy access.</div>
    <picture class="b-image-2">
        <!-- <source srcset="~@/assets/details-b-2.webp" type="image/webp"> -->
        <source srcset="~@/assets/sri_bus.png" type="image/png">
        <img src="~@/assets/sri_bus.png" alt="SRI 1" />
    </picture>
    <h5 class="h4 t-2">Public Transportation Hub</h5>
    <div class="p-2">Rather than being limited only to P&R shuttles, the CPRS’s strategic location allows it to integrate with the city’s public transportation and shared mobility network as a whole. A safe pedestrian area on the top of the complex provides direct access to multiple bus stops with a separate area for bus fleet parking. Where applicable, CPRS can also be integrated with train lines at the base of the structure.</div>
  </div>
</template>

<script>
export default {
  name: 'DetailsCPRS'
}
</script>

<style lang="scss" scoped>
.details-sri {
  @include grid;
  margin-top: 32px;
  .b-image-1 {
    grid-column: 2 / 7;
    grid-row: 1 / 2;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-1 {
    grid-column: 2 / 7;
    grid-row: 2 / 3;
    margin-top: 40px;
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 2 / 7;
    grid-row: 3 / 4;
    margin-top: 12px;
    margin-bottom: 96px;
    @include laptop {
      margin-bottom: 64px;
    }
    @include tablet {
      grid-column: 2 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .b-image-2 {
    grid-column: 8 / 13;
    grid-row: 1 / 2;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 4 / 5;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-2 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-top: 40px;
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 5 / 6;
    }
  }
  .p-2 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    margin-top: 12px;
    margin-bottom: 96px;
    @include laptop {
      margin-bottom: 64px;
    }
    @include tablet {
      grid-column: 6 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      grid-row: 6 / 7;
    }
  }
}

</style>
