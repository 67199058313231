<template>
  <div class="content-4 up-1" v-scrollanimate>
    <picture class="image-4">
      <source srcset="~@/assets/cprs_c.webp" type="image/webp">
      <source srcset="~@/assets/cprs_c.jpg" type="image/jpeg">
      <img src="~@/assets/cprs_c.jpg" alt="CPRS commercial center" />
    </picture>
    <div class="p-1"><h4 class="h4">Urban Services Hub</h4><br>Urban services such as logistical and industrial centers can benefit from strategic positioning at urban highway interchanges. Located within or on top of a CPRS, they can be built in direct proximity to the city without requiring any additional land. Park & Ride spaces can also double as night hangars for fleets of MaaS vehicles in need of charging and maintenance.<br><br><span class="bold">Such opportunities provide business incentives to help financing the entire complex, and can be uniquely adapted to the specific needs of each city.</span></div>
    <!-- <div class="tech bold"><span class="noselect t-click eff-base-r eff-2 eff-b" @click="showExpand = !showExpand">Technical details <span v-html="symbol()"></span></span></div>
    <ExpandEVC class="expanded" :class="{active: showExpand}" /> -->
  </div>
</template>

<script>
// import ExpandEVC from '../components/ExpandEVC'

export default {
  name: 'SolutionContent2'
  // components: {
  //   ExpandEVC
  // },
  // data () {
  //   return {
  //     showExpand: false,
  //     symbolPlus: '+',
  //     symbolMinus: '–'
  //   }
  // },
  // methods: {
  //   symbol () {
  //     const closed = this.symbolPlus
  //     const open = this.symbolMinus
  //     if (this.showExpand) return open
  //     return closed
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.content-4 {
  @include grid;
  margin-bottom: 128px;
  @include laptop {
    margin-bottom: 96px;
  }
  @include tablet {
    margin-bottom: 64px;
  }
  .image-4 {
    grid-column: 1 / 15;
    grid-row: 1 / 2;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-1 {
    grid-column: 2 / 7;
    grid-row: 2 / 3;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 6;
      margin-top: 64px;
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 3 / 4;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .tech {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    margin-top: 32px;
    @include tablet {
      grid-column: 2 / 6;
      grid-row: 4 / 5;
    }
    .t-click {
      cursor: pointer;
    }
  }
  .expanded {
    grid-column: 1 / 15;
    grid-row: 4 / 5;
    transition: 0.4s ease;
    max-height: 0;
    overflow: hidden;
    @include tablet {
      grid-column: 1 / 11;
      grid-row: 5 / 6;
    }
    @include phone {
      grid-column: 1 / 7;
    }
  }
  .active {
    max-height: 500px;
    transition: all 1s ease;
  }
}

</style>
